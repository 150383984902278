export function goodDisplayForNumbers (number, fixedNumber = null, round = false, removeChar = null) {
  let firstPart;
  let lastPart;
  let cuttedNumber;

  if (!number) {
    return 0;
  }

  let formattedNumber;

  // remove potential char (accept only numbers)
  if (removeChar) {
    if (number.toString().indexOf(removeChar) >= 0) {
      number = number.toString().substr(1);
      number = number * 1;
    }
  }

  // limit number after the comma
  if (fixedNumber !== null) {
    number = number.toFixed(fixedNumber);
  }

  // round the number
  if (round) {
    number = Math.round(number);
  }

  if (number.toString().indexOf('.') > 0) {
    cuttedNumber = number.toString().split('.');
    firstPart = cuttedNumber[0];
    lastPart = cuttedNumber[1];

    formattedNumber = `${applyFormatter(firstPart)},${lastPart}`;
  } else if (number.toString().indexOf(',') > 0) {
    cuttedNumber = number.toString().split(',');
    firstPart = cuttedNumber[0];
    lastPart = cuttedNumber[1];

    formattedNumber = `${applyFormatter(firstPart)},${lastPart}`;
  } else {
    formattedNumber = applyFormatter(number);
  }

  return formattedNumber;
}

export function dateTransform (date) {
  let wantedDate = new Date(date);

  let day = wantedDate.getDate().toString().length > 1 ? wantedDate.getDate() : `0${wantedDate.getDate()}`;
  let month = (wantedDate.getMonth()+1).toString().length > 1 ? wantedDate.getMonth()+1 : `0${wantedDate.getMonth()+1}`;
  let year = wantedDate.getFullYear();

  return `${day}-${month}-${year}`;
}

function applyFormatter (number) {
  return number.toString().split('').reverse().join('').match(/.{1,3}/g).join(' ').split('').reverse().join('');
}
