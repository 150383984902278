import ahoy from 'ahoy.js';

class AhoyTracking {
  constructor () {
    if (!AhoyTracking.instance) {
      AhoyTracking.instance = this;
    }

    let dev = process.env.NODE_ENV === 'development';
    this.configure(dev);
    this.trackAll();

    this.trackingContext = '$investirou_';

    return AhoyTracking.instance;
  }

  configure (dev = false) {
    if (dev) {
      this.reset();
      this.debug(dev);
    }

    let utmCampaign;
    let utmSource;
    let utmMedium;

    let queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('utm_source')) {
      utmSource = urlParams.get('utm_source');
    }
    if (urlParams.get('utm_medium')) {
      utmMedium = urlParams.get('utm_medium');
    }
    if (urlParams.get('utm_campaign')) {
      utmCampaign = urlParams.get('utm_campaign');
    }

    ahoy.configure({
      urlPrefix: dev ? 'http://localhost:5000' : 'https://app.vivrou.com',
      visitsUrl: "/steps/visits",
      eventsUrl: "/steps/events",
      page: 'Investirou.com',
      platform: 'Web',
      useBeacon: true,
      startOnReady: true,
      trackVisits: true,
      cookies: true,
      cookieDomain: null,
      headers: {},
      visitParams: { landing_page: 'https://www.investirou.com/', referrer: 'https://www.investirou.com/', utm_source: utmSource, utm_medium: utmMedium, utm_campaign: utmCampaign },
      withCredentials: false,
      visitDuration: 4 * 60, // 4 hours
      visitorDuration: 2 * 365 * 24 * 60 // 2 years
    });
  }

  reset () {
    ahoy.reset();
  }

  debug (active = false) {
    ahoy.debug(active)
  }

  trackAll () {
    ahoy.trackView({ url: 'https://www.investirou.com/' });
  }

  trackClick (action, params = null) {
    params = { url: 'https://www.investirou.com/', ...params };
    ahoy.track(`${this.trackingContext}click_${action}`, params || {});
  }

  trackFormChanged (field, params = null) {
    params = { url: 'https://www.investirou.com/', ...params };
    ahoy.track(`${this.trackingContext}form_contact_field_changed_${field}`, params || {});
  }

  trackFormSubmit (params = null) {
    params = { url: 'https://www.investirou.com/', ...params };
    ahoy.track(`${this.trackingContext}form_contact_submit`, params || {});
  }

  trackScroll (params = null) {
    params = { url: 'https://www.investirou.com/', ...params };
    ahoy.track(`${this.trackingContext}scroll`, params || {});
  }
}

const ahoyTracking = new AhoyTracking();
export default ahoyTracking;
