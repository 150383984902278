import L from 'leaflet';
import * as $ from 'jquery';
import config from './config.js';
import Marker from './Marker.js';
import PopinInfoBlock from './PopinInfoBlock.js';
import ZoomHomeControl from './plugins/ZoomHomeControl.js';
import LegendBlock from './LegendBlock.js';
import ahoyTracking from './AhoyTracking.js';

export default class InvestirouMap {
  constructor (el, options) {
    this.el = el;

    this.defaultLatAndLon = options.defaultLatAndLon;
    this.defaultRadius = options.radius;
    this.defaultSiren = options.siren;

    this.maxMapBoundingBox = null;

    this.displayedLayer = undefined;
    this.transportLinesLayer = undefined;

    this.activeMarker = null;

    L.DomEvent.disableScrollPropagation(document.getElementById('action-link-container-mobile'));
    L.DomEvent.disableClickPropagation(document.getElementById('action-link-container-mobile'));

    L.DomEvent.disableScrollPropagation(document.getElementById('map-background-selector-container'));
  }

  createMap () {
    return new Promise((resolve, reject) => {
      try {
        this.map = L.map(this.el, {
          zoomControl: false,
          center: this.defaultLatAndLon,
          zoom: config._DEFAULT_ZOOM,
          closePopupOnClick: false,
          preferCanvas: true,
          doubleClickZoom: false
        });

        this.addZoomHomeControl();

        this.map.on ({
          click: (event) => {
            this.popinInfoBlock.close();
            this.releaseClickedMarker();
            this.releaseCurrentSelectedUrbanProject();

            if (!$('#map-background-selector-container').hasClass('d-none')) {
              $('#map-background-selector-container').toggleClass('d-none');
            }
          }
        });

        this.loadMapBackgrounds();

        this.full_white_map.addTo(this.map);
        this.currentMapBackgroundName = 'light';
        this.currentMapBackground = this.full_white_map;

        this.defineMaxMapBounds();
        this.map.setMaxBounds(this.maxMapBoundingBox);

        this.map.whenReady(() => {
          this.popinInfoBlock = new PopinInfoBlock();
          this.legendBlock = new LegendBlock();

          resolve();
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  loadMapBackgrounds () {
    this.osm_france = L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
      minZoom: config._MIN_ZOOM,
      maxZoom: config._MAX_ZOOM,
      attribution: `&copy; <a target="_blank" href="https://www.e-attract.com/smart-maps?ref=">e-attract</a> | <a target="_blank" href="http://www.openstreetmap.org/copyright">OSM</a>`
    });

    this.full_black_map = L.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png', {
      minZoom: config._MIN_ZOOM,
      maxZoom: config._MAX_ZOOM,
      attribution: `&copy; <a target="_blank" href="https://www.e-attract.com/smart-maps?ref=e-attract">e-attract</a> | <a target="_blank" href="https://carto.com">Carto</a>`
    });

    this.full_white_map = L.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {
      minZoom: config._MIN_ZOOM,
      maxZoom: config._MAX_ZOOM,
      attribution: `&copy; <a target="_blank" href="https://www.e-attract.com/smart-maps?ref=e-attract">e-attract</a> | <a target="_blank" href="https://carto.com">Carto</a>`
    });
  }

  addZoomHomeControl () {
    new L.Control.zoomHome({ centerMapLatLon: this.getDefaultLatAndLon(), zoomLevel: config._DEFAULT_ZOOM, position: 'topleft' }).addTo(this.map);
  }

  getDefaultLatAndLon () {
    return this.defaultLatAndLon;
  }

  defineMaxMapBounds () {
    let circle = L.circle(this.defaultLatAndLon, {
      radius: this.defaultRadius,
      fill: false,
      stroke: false
    }).addTo(this.map);

    let rectangle = L.rectangle(circle.getBounds(), {
      weight: 0,
      fill: false
    }).addTo(this.map);

    this.maxMapBoundingBox = rectangle.getBounds();
  }

  changeMapBackground (mapBackground) {
    if (mapBackground === this.currentMapBackgroundName) {
      return;
    }

    $('a[data-background-action]').removeClass('active');
    $(`a[data-background-action="${mapBackground}"]`).addClass('active');

    this.map.removeLayer(this.currentMapBackground);

    this.currentMapBackgroundName = mapBackground;
    switch (mapBackground) {
      case 'night':
        this.map.addLayer(this.full_black_map);
        this.currentMapBackground = this.full_black_map;
        break;
      case 'osm_france':
        this.map.addLayer(this.osm_france);
        this.currentMapBackground = this.osm_france;
        break;
      case 'light':
        this.map.addLayer(this.full_white_map);
        this.currentMapBackground = this.full_white_map;
        break;
    }
  }

  getMaxMapBounds () {
    let swLon = this.maxMapBoundingBox.getSouthWest().lng;
    let swLat = this.maxMapBoundingBox.getSouthWest().lat;
    let neLon = this.maxMapBoundingBox.getNorthEast().lng;
    let neLat = this.maxMapBoundingBox.getNorthEast().lat;

    return { xmin: swLon, ymin: swLat, xmax: neLon, ymax: neLat };
  }

  getBoundingBox () {
    let swLon = this.map.getBounds().getSouthWest().lng;
    let swLat = this.map.getBounds().getSouthWest().lat;
    let neLon = this.map.getBounds().getNorthEast().lng;
    let neLat = this.map.getBounds().getNorthEast().lat;

    return { xmin: swLon, ymin: swLat, xmax: neLon, ymax: neLat };
  }

  displayRealEstates (data) {
    if (data && data.features && data.features.length > 0) {
      this.realEstateLayer = L.featureGroup().addTo(this.map);

      let realEstatesGeoJson = L.geoJSON(data.features, {
        pointToLayer: (geoJsonPoint, latlng) => {
          return new Marker(geoJsonPoint.properties, latlng, geoJsonPoint.id).getMarker();
        }
      }).addTo(this.realEstateLayer);
    }
  }

  displayUrbanProjects (data) {
    this.administrativeDivisionLayer.removeFrom(this.map);
    this.administrativeDivisionLayerWithoutClick.removeFrom(this.map);

    this.displayInteractiveLayers(false);

    if (data && data.features && data.features.length > 0) {
      this.displayedLayer = L.featureGroup().addTo(this.map);

      let circleMarkerOption = {
        color: '#EA6621',
        fill: true,
        fillOpacity: 1,
        fillColor: '#1B2437',
        weight: 2,
        radius: 5
      };

      let urbanProjectsGeoJson = L.geoJSON(data.features, {
        pointToLayer: (geoJsonPoint, latlng) => {
          return L.circleMarker(latlng, circleMarkerOption);
        },
        style: (geoJsonFeature) => {
          let color = '#FFF';

          switch (geoJsonFeature.properties.category) {
            case 'transport_infrastructure':
              color = '#8da0cb';
              break;
            case 'general':
              color = '#fcba03';
              break;
          }
          return {
            color: color,
            fillOpacity: 0.2,
            fillColor: color,
            weight: 4
          };
        },
        onEachFeature: (feature, layer) => {
          layer.on({
            mouseover: (e) => {
              if (e && e.target) {
                e.target.setStyle({ color: 'red', weight: 5 });
                e.target.bringToFront();
              }
            },
            mouseout: (e) => {
              if (e && e.target) {
                if (!this.currentSelectedUrbanProject || feature.id !== this.currentSelectedUrbanProject.feature.id) {
                  e.target.setStyle({ color: feature.properties.category === 'transport_infrastructure' ? '#8da0cb' : '#fcba03', weight: 3 });
                }
              }
            },
            click: (e) => {
              L.DomEvent.stopPropagation(e);

              this.releaseCurrentSelectedUrbanProject();

              this.currentSelectedUrbanProject = layer;
              console.log(feature)
              let data = {
                title: feature.properties.title,
                description: feature.properties.description,
                pictures: feature.properties.pictures,
                delivery_date: feature.properties.delivery_date,
                start_date: feature.properties.start_date,
                url_project: feature.properties.url_project,
                data: feature.properties.data
              };
              window.investirouMap.popinInfoBlock.loadData('urban-project', data);

              ahoyTracking.trackClick('urban_project', { 'id': feature.id });
            }
          });
        }
      }).addTo(this.displayedLayer);
    }
  }

  releaseCurrentSelectedUrbanProject () {
    if (this.currentSelectedUrbanProject) {
      let current = this.currentSelectedUrbanProject;
      this.currentSelectedUrbanProject = undefined
      current.fire('mouseout');
    }
  }

  displayCartoFragmentation (data) {
    this.administrativeDivisionLayer.removeFrom(this.map);
    this.administrativeDivisionLayerWithoutClick.removeFrom(this.map);

    if (data && data.features && data.features.length > 0) {
      let dataToDisplay = data.metadata.popup;

      this.displayedLayer = L.featureGroup().addTo(this.map);

      let popupUnit = '';
      let popupTitle = '';
      switch (data.type) {
        case 'price_evolution':
          popupUnit = '%';
          popupTitle = "Evolution des prix de l'immobilier entre 2014 et 2021";
          break;
        case 'rental_pressures':
          popupUnit = 'jours';
          popupTitle = "Durée de vie d'une annonce locative en ligne";
          break;
        case 'return_rates':
          popupUnit = '%';
          popupTitle = "Rentabilité brute";
          break;
      }

      let realEstatesGeoJson = L.geoJSON(data.features, {
        style: (geoJsonFeature) => {
          return {
            color: 'white',
            fillColor: geoJsonFeature.properties.color,
            fillOpacity: 0.6
          };
        },
        onEachFeature: (feature, layer) => {
          return layer.on('click', (e) => {
            L.DomEvent.stopPropagation(e);

            let popinData = {
              title: feature.properties.name,
              description: feature.properties.description ? feature.properties.description : null,
              population: feature.properties.population ? feature.properties.population : null,
              s3_photo_uri: feature.properties.s3_photo_uri ? feature.properties.s3_photo_uri : null
            };

            dataToDisplay.forEach((dataDisplay, index) => {
              popinData[`data_${index}`] = {
                title : dataDisplay.title !== '' ? dataDisplay.title : popupTitle,
                value : feature.properties[dataDisplay.key],
                unit: dataDisplay.key !== 'rank' ? popupUnit : null,
                total: dataDisplay.key === 'rank' ? data.features.length : null
              }
            });

            window.investirouMap.popinInfoBlock.loadData('fragmentation-info', popinData);

            ahoyTracking.trackClick(`commune_${data.type}`, { 'national_code': feature.properties.national_code });
          });
        }
      }).on('mouseover', event => {
        this.displayInteractiveLayerName(event.layer.feature.properties.name);
      }).on('mouseout', () => {
        this.hideInteractiveLayerName();
      }).addTo(this.displayedLayer);
    }

    if (data) {
      this.legendBlock.display(data);
    }
  }

  loadInteractiveLayers (data) {
    if (data && data.features && data.features.length > 0) {
      this.administrativeDivisionLayer = L.featureGroup();

      let interactiveLayersGeoJson = L.geoJSON(data.features, {
        style: (geoJsonFeature) => {
          return {
            fillOpacity: 0,
            color: '#9EA8BD'
          };
        },
        onEachFeature: (feature, layer) => {
          return layer.on('click', (e) => {
            L.DomEvent.stopPropagation(e);

            let popinData = {
              title: feature.properties.name,
              description: feature.properties.description ? feature.properties.description : null,
              population: feature.properties.population ? feature.properties.population : null,
              s3_photo_uri: feature.properties.s3_photo_uri ? feature.properties.s3_photo_uri : null
            };

            window.investirouMap.popinInfoBlock.loadData('fragmentation-info', popinData);

            ahoyTracking.trackClick('commune', { 'national_code': feature.properties.national_code });
          });
        }
      }).on('mouseover', event => {
        this.displayInteractiveLayerName(event.layer.feature.properties.name);
      }).on('mouseout', () => {
        this.hideInteractiveLayerName();
      }).addTo(this.administrativeDivisionLayer);
    }
  }

  loadInteractiveLayersWithoutClick (data) {
    if (data && data.features && data.features.length > 0) {
      this.administrativeDivisionLayerWithoutClick = L.featureGroup();

      let interactiveLayersGeoJson = L.geoJSON(data.features, {
        style: (geoJsonFeature) => {
          return {
            fillOpacity: 0,
            color: '#9EA8BD'
          };
        }
      }).on('mouseover', event => {
        this.displayInteractiveLayerName(event.layer.feature.properties.name);
      }).on('mouseout', () => {
        this.hideInteractiveLayerName();
      }).addTo(this.administrativeDivisionLayerWithoutClick);
    }
  }

  displayInteractiveLayerName (name) {
    $('#administrative-area-name-container').html(name).removeClass('d-none');
  }

  hideInteractiveLayerName () {
    $('#administrative-area-name-container').html('').addClass('d-none');
  }

  displayInteractiveLayers (withClick = true) {
    if (withClick) {
      this.administrativeDivisionLayer.addTo(this.map);
    } else {
      this.administrativeDivisionLayerWithoutClick.addTo(this.map);
    }
  }

  removeDisplayedLayer () {
    this.legendBlock.hide();
    this.removeTransportLines();

    if (typeof this.displayedLayer !== 'undefined') {
      this.displayedLayer.clearLayers();
      this.displayedLayer = undefined;
    }
  }

  releaseClickedMarker () {
    if (this.activeMarker) {
      L.DomUtil.removeClass(this.activeMarker._icon, 'active');
      this.activeMarker.closeTooltip();
    }
  }

  loadTransportLines (data) {
    if (data && data.features && data.features.length > 0) {
      this.transportLinesLayer = L.featureGroup().addTo(this.map);

      let transportLinesGeoJson = L.geoJSON(data.features, {
        style: (geoJsonFeature) => {
          let color = '#FFF';

          return {
            color: geoJsonFeature.properties.color || color,
            fillOpacity: 0.2,
            fillColor: color,
            weight: 4
          };
        }
      }).bindTooltip(layer => {
        let type = '';
        if (layer.feature.properties.category === 0) {
          type = 'Métro';
           if (layer.feature.properties.line.indexOf('F') >= 0) {
            type = 'Funiculaire';
          }
        } else {
          type = 'Tram';
          if (layer.feature.properties.line === 'RX') {
            type = '';
          }
        }

        return `<span style="color: ${layer.feature.properties.color}">${type} ${layer.feature.properties.line}</span>`
      }, {
        sticky: true,
        direction: 'top',
        permanent: false
      }).addTo(this.transportLinesLayer);
    }
  }

  displayTransportLines () {
    if (typeof this.transportLinesLayer !== 'undefined') {
      this.transportLinesLayer.addTo(this.map);
    }
  }

  removeTransportLines () {
    if (typeof this.transportLinesLayer !== 'undefined') {
      this.transportLinesLayer.removeFrom(this.map);
    }
  }
};
