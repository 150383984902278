import * as $ from 'jquery';

class InvestirouBackend {
  constructor () {
    if (!InvestirouBackend.instance) {
      InvestirouBackend.instance = this;
    }

    this.token = '0m6OgOMiKB3pZmhCi3jJPwtt';
    this.url = 'https://api.vivrou.com';
    this.siren = null;

    return InvestirouBackend.instance;
  }

  setSiren (siren) {
    this.siren = siren;
  }

  fetchAdministrativeAreas (specificBoundingBox) {
    return new Promise((resolve, reject) => {
      let { xmin, ymin, xmax, ymax } = specificBoundingBox;

      $.ajax({
        url: `${this.url}/v2/investirou/epci/${this.siren}?xmin=${xmin}&ymin=${ymin}&xmax=${xmax}&ymax=${ymax}`,
        type: 'GET',
        contentType: 'application/json',
        headers: { "Authorization": "Token token=" + this.token }
      }).done(response => {
        resolve(response);
      }).fail(error => {
        reject(error);
      });
    });
  }

  fetchSmartMapRealEstate (specificBoundingBox) {
    return new Promise((resolve, reject) => {
      let { xmin, ymin, xmax, ymax } = specificBoundingBox;

      $.ajax({
        url: `${this.url}/v2/investirou/real_estate_listings/${this.siren}`,
        type: 'GET',
        contentType: 'application/json',
        headers: { "Authorization": "Token token=" + this.token }
      }).done(response => {
        resolve(response);
      }).fail(error => {
        reject(error);
      });
    });
  }

  fetchUrbanProjects (specificBoundingBox) {
    return new Promise((resolve, reject) => {
      let { xmin, ymin, xmax, ymax } = specificBoundingBox;

      $.ajax({
        url: `${this.url}/v2/urban_projects?xmin=${xmin}&ymin=${ymin}&xmax=${xmax}&ymax=${ymax}&year=2100`,
        type: 'GET',
        contentType: 'application/json',
        headers: { "Authorization": "Token token=" + this.token }
      }).done(response => {
        resolve(response);
      }).fail(error => {
        reject(error);
      });
    });
  }

  fetchPriceEvolution (latlon) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${this.url}/v2/investirou/real_estate_prices/${this.siren}/prices`,
        type: 'GET',
        contentType: 'application/json',
        headers: { "Authorization": "Token token=" + this.token }
      }).done(response => {
        response.type = 'price_evolution';
        resolve(response);
      }).fail(error => {
        reject(error);
      });
    });
  }

  fetchRentalPressures (latlon) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${this.url}/v2/investirou/real_estate_prices/${this.siren}/rental_pressures`,
        type: 'GET',
        contentType: 'application/json',
        headers: { "Authorization": "Token token=" + this.token }
      }).done(response => {
        response.type = 'rental_pressures';
        resolve(response);
      }).fail(error => {
        reject(error);
      });
    });
  }

  fetchRates (latlon) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${this.url}/v2/investirou/real_estate_prices/${this.siren}/return_rates`,
        type: 'GET',
        contentType: 'application/json',
        headers: { "Authorization": "Token token=" + this.token }
      }).done(response => {
        response.type = 'return_rates';
        resolve(response);
      }).fail(error => {
        reject(error);
      });
    });
  }

  registration (userData) {
    return new Promise((resolve, reject) => {
      let url = process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : this.url;
      $.post(`${url}/v2/investirou/registrations`, { user: userData }).done(response => {
        resolve(response);
      }).fail(error => {
        reject(error);
      });
    });
  }

  fetchTransportLines (latlon) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${this.url}/v2/transport_lines?lon=${latlon[1]}&lat=${latlon[0]}`,
        type: 'GET',
        contentType: 'application/json',
        headers: { "Authorization": "Token token=" + this.token }
      }).done(response => {
        resolve(response);
      }).fail(error => {
        reject(error);
      });
    });
  }
}

const investirouBackend = new InvestirouBackend();
export default investirouBackend;
