export default {
  _MIN_ZOOM: 10,
  _MAX_ZOOM: 15,
  _DEFAULT_ZOOM: 11,
  _DEFAULT_LATLON: [45.75, 4.85],
  _DEFAULT_RADIUS: 40000,
  _DEFAULT_SIREN: '200046977',
  _CITIES: {
    'lyon': { latlon: [45.75, 4.85], radius: 40000, siren: '200046977' },
    'paris': { latlon: [48.8534, 2.3488], radius: 40000, siren: '200054781' },
    'bordeaux': { latlon: [44.8333, -0.5667], radius: 40000, siren: '243300316' },
  }
}
