import * as $ from 'jquery';
import config from './config.js';

import InvestirouMap from './InvestirouMap.js';
import investirouBackend from './InvestirouBackend.js';
import ahoyTracking from './AhoyTracking.js';

let map;

if ($('#investirou-map').length) {
  let queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  let mapOptions = {
    defaultLatAndLon: config._DEFAULT_LATLON,
    radius: config._DEFAULT_RADIUS,
    siren: config._DEFAULT_SIREN
  }

  if (urlParams.get('city')) {
    if (typeof config._CITIES[urlParams.get('city')] !== 'undefined') {
      mapOptions.defaultLatAndLon = config._CITIES[urlParams.get('city')].latlon;
      mapOptions.radius = config._CITIES[urlParams.get('city')].radius;
      mapOptions.siren = config._CITIES[urlParams.get('city')].siren;
    }
  }

  // set the city siren params for api url
  investirouBackend.setSiren(mapOptions.siren);

  map = new InvestirouMap('investirou-map', mapOptions);
  map.createMap().then(response => {
    investirouBackend.fetchAdministrativeAreas(map.getMaxMapBounds())
      .then(response => {
        map.loadInteractiveLayers(response);
        map.loadInteractiveLayersWithoutClick(response);

        map.displayInteractiveLayers();
      }).catch(error => {
        console.error('An error occured');
      });
    investirouBackend.fetchSmartMapRealEstate(map.getMaxMapBounds())
      .then(response => {
        map.displayRealEstates(response);
      }).catch(error => {
        console.error('An error occured');
      });

    bindPageAction();
  }).catch(error => {
    console.error('Error during map init');
  });

  window.investirouMap = map;
}

function bindPageAction () {
  $('[data-map-action]').off().on('click', (e) => {
    e.preventDefault();

    let currentElement = e.currentTarget;

    // add specific button class
    $('[data-map-action]').removeClass('bg-thirdly text-white shadow');
    $('[data-map-action]').removeClass('active');
    $('.link-arrow', '[data-map-action]').addClass('d-none');
    $(currentElement).addClass('bg-thirdly text-white shadow');
    $(currentElement).addClass('active');
    $('.link-arrow', currentElement).removeClass('d-none')

    // handle mobile class
    $('#action-link-container-mobile').addClass('d-none');
    $('#action-link-container-mobile').removeClass('d-flex');
    $('#action-link-container-mobile-open').removeClass('d-none');

    if (window.openedInformation) {
      $('.action-link-information').not($(currentElement).find('.action-link-information')).removeClass('d-flex');
      $('.action-link-information', currentElement).addClass('d-flex');
    } else {
      // remove information popin if opened
      $('.action-link-information', currentElement).removeClass('d-flex');
    }

    // active spinners
    toggleMapActionSpinnerButton(currentElement);

    let action = $(currentElement).data('map-action');

    ahoyTracking.trackClick(`${action}`);

    switch (action) {
      case 'urban_projects':
        map.removeDisplayedLayer();
        if (map.popinInfoBlock.type !== '' && map.popinInfoBlock.type !== 'real-estate-marker') {
          map.popinInfoBlock.close();
        }

        investirouBackend.fetchUrbanProjects(window.investirouMap.getBoundingBox())
          .then(response => {
            map.displayUrbanProjects(response);

            toggleMapActionSpinnerButton(currentElement);
          }).catch(error => {
            console.error(error);
            toggleMapActionSpinnerButton(currentElement);
          });

        if (typeof map.transportLinesLayer !== 'undefined') {
          map.displayTransportLines();
        } else {
          investirouBackend.fetchTransportLines(map.getDefaultLatAndLon())
            .then(response => {
              map.loadTransportLines(response);
            }).catch(error => {
              console.error(error);
            });
        }

        $('.map-message-container').text('Les projets qui vont transformer le territoire');
        break;
      case 'price_evolution':
        map.removeDisplayedLayer();
        if (map.popinInfoBlock.type !== '' && map.popinInfoBlock.type !== 'real-estate-marker') {
          map.popinInfoBlock.close();
        }

        investirouBackend.fetchPriceEvolution(map.getDefaultLatAndLon())
          .then(response => {
            map.displayCartoFragmentation(response);

            toggleMapActionSpinnerButton(currentElement);
          }).catch(error => {
            console.error(error);
            toggleMapActionSpinnerButton(currentElement);
          });

        $('.map-message-container').text('Plus c’est vert foncé, plus les prix immobiliers ont augmenté');
        break;
      case 'rental_pressures':
        map.removeDisplayedLayer();
        if (map.popinInfoBlock.type !== '' && map.popinInfoBlock.type !== 'real-estate-marker') {
          map.popinInfoBlock.close();
        }

        investirouBackend.fetchRentalPressures(map.getDefaultLatAndLon())
          .then(response => {
            map.displayCartoFragmentation(response);

            toggleMapActionSpinnerButton(currentElement);
          }).catch(error => {
            console.error(error);
            toggleMapActionSpinnerButton(currentElement);
          });

        $('.map-message-container').text('Plus c’est vert foncé, plus vous allez louer vite votre bien');
        break;
      case 'rates':
        map.removeDisplayedLayer();
        if (map.popinInfoBlock.type !== '' && map.popinInfoBlock.type !== 'real-estate-marker') {
          map.popinInfoBlock.close();
        }

        investirouBackend.fetchRates(map.getDefaultLatAndLon())
          .then(response => {
            map.displayCartoFragmentation(response);

            toggleMapActionSpinnerButton(currentElement);
          }).catch(error => {
            console.error(error);
            toggleMapActionSpinnerButton(currentElement);
          });

        $('.map-message-container').text('Plus c’est vert foncé, plus la rentabilité brute est élevée');
        break;
      case 'administrative-areas':
        map.removeDisplayedLayer();
        if (map.popinInfoBlock.type !== '' && map.popinInfoBlock.type !== 'real-estate-marker') {
          map.popinInfoBlock.close();
        }

        map.displayInteractiveLayers();
        toggleMapActionSpinnerButton(currentElement);
        break;
    }
  });

  $('a[data-background-action]').off().on('click', (e) => {
    e.preventDefault();

    let currentElement = e.currentTarget;
    let action = $(currentElement).data('background-action');

    map.changeMapBackground(action);

    ahoyTracking.trackClick('map_background_selector', { 'map_background': action } );
  });
}

function toggleMapActionSpinnerButton (element) {
  $('.spinner-border', element).toggleClass('d-none');
}
