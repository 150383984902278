import 'bootstrap';
import * as $ from 'jquery';
import ahoyTracking from './AhoyTracking.js';

import settingscss from '../assets/stylesheets/settings.scss';
import generalcss from '../assets/stylesheets/style.scss';
import mapcss from '../assets/stylesheets/map.scss';
import mapinfoblock from '../assets/stylesheets/map-info-block.scss';

import ea from '../assets/images/logos/e-attract.svg';
import plusse from '../assets/images/logos/plusse.svg';
import anchor from '../assets/images/ancre-bas.svg'
import investirouLyonWhite from '../assets/images/logos/investirou-lyon-white-white.svg';
import detente from '../assets/images/detente.png';
import securite from '../assets/images/securite.png';
import linkedin from '../assets/images/linkedin.svg';
import twitter from '../assets/images/twitter.svg';
import facebook from '../assets/images/facebook.svg';
import footer from '../assets/images/logos/investirou-lyon-white-footer.svg';
import carte from '../assets/images/carte-on.svg';
import mobileHome from '../assets/images/home.svg';
import slideTop from '../assets/images/slide-top.svg';
import legend from '../assets/images/legend.svg';

import favico from '../assets/images/investirou-fav.svg';
import favico57 from '../assets/images/investirou-fav-57x57.png';
import favico72 from '../assets/images/investirou-fav-72x72.png';
import favico114 from '../assets/images/investirou-fav-114x114.png';

import './MapEntryPoint';
import './registrationForm';

window.openedInformation = false;

// bind the know more button
$('.know-more').off().on('click', () => {
  ahoyTracking.trackClick('know_more_button');
  $(window).off('scroll', launchScrollTracking);

  $('html, body').animate({ scrollTop: $(`#information_section`).offset().top - $('.header-mobile').get(0).getBoundingClientRect().height }, 1000, () => {
    $(window).on('scroll', launchScrollTracking);
  });
});

// bind the action link information open button
$('.action-link-information-button').off().on('click', (event) => {
  event.preventDefault();
  event.stopPropagation();

  window.openedInformation = !window.openedInformation;

  let concern = $(event.currentTarget).parent().data('map-action');
  ahoyTracking.trackClick(`help_button_${concern}`);

  $('.action-link-information').not($(event.currentTarget).parent().find('.action-link-information')).removeClass('d-flex');
  $(event.currentTarget).parent().find('.action-link-information').toggleClass('d-flex');
});

// bind the action link information close button
$('.action-link-information-close').off().on('click', (event) => {
  event.preventDefault();
  event.stopPropagation();

  window.openedInformation = false;

  $(event.currentTarget).parent().toggleClass('d-flex');
});

// bind the header map opener for mobile
$('#action-link-container-mobile-open').off().on('click', ({ currentTarget }) => {
  $('#action-link-container-mobile').removeClass('d-none');
  $('#action-link-container-mobile').addClass('d-flex');
  $(currentTarget).addClass('d-none');

  if ($(currentTarget).hasClass('d-none')) {
    ahoyTracking.trackClick('mobile_switch_menu_button', { effect: 'open' });
  } else {
    ahoyTracking.trackClick('mobile_switch_menu_button', { effect: 'close' });
  }
});

// bind mobile header links to the correspondant section part
$('.mobile-anchor').off().on('click', (event) => {
  event.preventDefault();
  event.stopPropagation();
  let sectionId = $(event.currentTarget).attr('href');
  if (sectionId === '#investirou-map-container') {
    $('#action-link-container-mobile').toggleClass('d-none');
    $('#action-link-container-mobile').toggleClass('d-flex');
    $('#action-link-container-mobile-open').toggleClass('d-none');
  }

  let sectionTrackName;
  switch (sectionId) {
    case '#investirou-map-container':
      sectionTrackName = 'map';
      break;
    case '#information_section':
      sectionTrackName = 'information';
      break;
    case '#why_section':
      sectionTrackName = 'why';
      break;
    case '#contact_section':
      sectionTrackName = 'contact';
      break;
  }

  ahoyTracking.trackClick(`mobile_section_scroll_${sectionTrackName}`)
  $('html, body').animate({ scrollTop: $(sectionId).offset().top - $('.header-mobile').get(0).getBoundingClientRect().height }, 1000);
});

// bind contact buttons to the contact section part
$('.contact-button').off().on('click', (event) => {
  event.preventDefault();
  event.stopPropagation();

  $('html, body').animate({ scrollTop: $('#contact_section').offset().top - $('.header-mobile').get(0).getBoundingClientRect().height }, 1000);
  ahoyTracking.trackClick('contact_button', { 'section': previewElementView === 'investirou-map' ? 'map' : previewElementView });
});

$('#legend-container-mobile-opener').off().on('click', () => {
  $('#legend-container').toggle();
  let isVisible = $('#legend-container').is(":visible");
  ahoyTracking.trackClick('mobile_legend_button', { 'effect': isVisible ? 'open' : 'close' });
});

$('#legend-close-button-mobile').off().on('click', () => {
  $('#legend-container').toggle();
});

$('#scroll-top-button').off().on('click', (event) => {
  event.preventDefault();
  event.stopPropagation();

  $(window).off('scroll', launchScrollTracking);

  $('html, body').animate({ scrollTop: 0 }, 1000, () => {
    $(window).on('scroll', launchScrollTracking);
  });
  ahoyTracking.trackClick('scroll_top_button');
});

$('.action-link-information-button-mobile').off().on('click', ({ currentTarget }) => {
  event.preventDefault();
  event.stopPropagation();

  let action = $(currentTarget).parent().data('map-action');

  $('#popup-mobile-action-information').removeClass('d-none');

  let text;
  switch (action) {
    case 'price_evolution':
      text = "Visualisez l'évolution du prix immobilier neuf des différents territoires sur les 6 dernières années.";
      break;
    case 'rental_pressures':
      text = "Vous pouvez visualiser la tension locative de chaque commune : plus la tension locative est élevée, plus il sera facile de louer votre bien immobilier.";
      break;
    case 'rates':
      text = "Vous pouvez visualiser la rentabilité brute, qui est le ratio entre le revenu locatif annuel moyen et les prix de l'immobilier sur chaque territoire, avant toute fiscalité.";
      break;
    case 'urban_projects':
      text = "Les projets du territoire peuvent avoir un impact sur votre choix d'investissement. Nous vous en proposons un nombre non-exhaustif, sur les 30 prochaines années.";
      break;
  }
  $('#information-container', '#popup-mobile-action-information').text(text);

  ahoyTracking.trackClick(`mobile_help_button_${action}`);
});

$('#popup-mobile-action-information-closer').off().on('click', () => {
  $('#popup-mobile-action-information').addClass('d-none');
  $('#information-container', '#popup-mobile-action-information').empty();
  ahoyTracking.trackClick(`mobile_close_popup_information_action`);
});

$('#action-link-mobile-explaination').off().on('click', () => {
  $('#action-link-mobile-explaination').addClass('d-none').removeClass('d-flex');
  ahoyTracking.trackClick('mobile_menu_overlay');
});


$('a').off().on('click', (event) => {
  let href = $(event.currentTarget).attr('href');
  if (href && href !== '#') {
    ahoyTracking.trackClick('page_link', { 'target': href });
  }
});


let scrollItems = ['investirou-map', 'information_section', 'why_section', 'contact_section', 'footer'];

positionButton();

$(window).on('scroll', launchScrollTracking);
$(window).on('scroll', positionButton);

function positionButton () {
  if (window.pageYOffset < $('h2', '#information_section').height()+20) {
    $('.floating-contact-button').css('right', `calc((${$('#investirou-map').width()}px / 2) - ${$('.floating-contact-button').width()}px / 2)`);
  } else {
    $('.floating-contact-button').css('right', 'calc(60px + 60px + 24px + 10px + 10px)');
  }
}

function defineElementView () {
  let alreadyDisplayedElements = scrollItems.map(id => {
    let domeRect = document.getElementById(id).getBoundingClientRect();
    if ( domeRect.top + (domeRect.height / 2) > 0 && (Math.round(domeRect.bottom) - (domeRect.height / 2) <= window.innerHeight) ) {
      return id;
    }
  }).filter(elmt => {
    if (elmt) {
      return elmt;
    }
  }).slice(-1);

  return alreadyDisplayedElements[0];
}

let previewElementView = 'investirou-map';
function launchScrollTracking (scrolled = false) {
  if (window.innerWidth >= 1024) {
    let elementView = defineElementView();
    if (typeof elementView !== 'undefined' && previewElementView !== elementView) {
      ahoyTracking.trackScroll({ 'section': elementView === 'investirou-map' ? 'map' : elementView });
      previewElementView = elementView;
    }
  }
}
