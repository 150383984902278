import { goodDisplayForNumbers } from './utils.js';
import ahoyTracking from './AhoyTracking.js';

export default class Marker {
  constructor (properties, latlng, id) {
    this.properties = properties;
    this.latlng = latlng;
    this.id = id;

    this.createIcon();

    this.createMarker();

    this.attachEvent();
  }

  attachEvent () {
    this.marker.on({
      click: (event) => {
        window.investirouMap.releaseClickedMarker();

        let data = this.properties;
        window.investirouMap.popinInfoBlock.loadData('real-estate-marker', data);

        ahoyTracking.trackClick('real_estate', { 'id': this.id });

        this.setClickedMarker(data.price);
      }
    });
  }

  createMarker () {
    this.marker = L.marker(this.latlng, { icon: this.icon });
  }

  createIcon () {
    let iconUrl = 'https://app.vivrou.com/packs/_/assets/images/smap/immo-neuf-53e587e9258a75cfe817200a52c05cd2.svg';
    if (this.properties.real_estate_developer_picture_url) {
      iconUrl = this.properties.real_estate_developer_picture_url;
    }

    let html = `<img src="${iconUrl}"/>`;

    let icon = L.divIcon({
      className: 'real-estate-marker',
      html,
      iconSize: L.point(22, 22)
    });

    this.icon = icon;
  }

  getMarker () {
    return this.marker;
  }

  setClickedMarker (price) {
    L.DomUtil.addClass(this.marker._icon, 'active');
    window.investirouMap.activeMarker = this.marker;

    let priceText = price ? `${goodDisplayForNumbers(price)} €` : 'Prix non communiqué';

    this.marker.bindTooltip(priceText, { permanent: true, direction: 'right' });
  }
}
