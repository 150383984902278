import * as $ from 'jquery';
import { goodDisplayForNumbers, dateTransform } from './utils.js';
import ahoyTracking from './AhoyTracking.js';

export default class PopinInfoBlock {

  constructor () {
    this.ELEMENT_SELECTOR_ID = 'investirou-map-info';
    this.DOM_ELEMENT = $(`#${this.ELEMENT_SELECTOR_ID}`);
    this.DOM_ELEMENT_CONTAINER = $('.map-info-container', `#${this.ELEMENT_SELECTOR_ID}`);
    this.type = '';
  }

  close () {
    this.unbindButtons();
    this.unloadData();
    this.DOM_ELEMENT.addClass('hidden');
    window.investirouMap.releaseCurrentSelectedUrbanProject();
  }

  open () {
    this.DOM_ELEMENT.removeClass('hidden');
    L.DomEvent.disableScrollPropagation(document.getElementById(this.ELEMENT_SELECTOR_ID));
    L.DomEvent.disableClickPropagation(document.getElementById(this.ELEMENT_SELECTOR_ID));

    this.bindButtons();
  }

  loadData (type, data) {
    this.unloadData();
    this.type = type;

    let htmlPopin = '';

    this.setTitle(type, data);

    htmlPopin = '';
    htmlPopin += `
      <div class="popin-title position-sticky p-2 bg-white font-weight-bold ${type === 'fragmentation-info' ? 'border-bottom border-fifthly' : ''}">
        <p class="m-0 pr-4">${this.title}</p>
        <div class="close-map-info position-absolute d-flex align-items-center"><p class="m-0 font-weight-normal text-thirdly" id="close-map-info">✕</p></div>`;

    if (type === 'fragmentation-info' && data.population) {
      htmlPopin += '<div class="font-weight-normal">';
      htmlPopin += `<p class="m-0"><i>${goodDisplayForNumbers(data.population)} habitants en 2019</i></p>`;
      htmlPopin += '</div>';
    }

    htmlPopin += `</div>`;
    htmlPopin += `<div id="${type}" class="w-100">`;
    htmlPopin += '<div>';

    switch (type) {
      case 'real-estate-marker':
        htmlPopin += '<div class="border-bottom border-fifthly p-2">';
        htmlPopin += data.address ? `<p class="m-0 text-capitalize">${data.address}</p>` : '';
        htmlPopin += data.estate_developer ? `<p class="m-0 text-uppercase">${data.estate_developer}</p>` : '';
        htmlPopin += '</div>';

        if (data.delivery_date || data.nombre_logements || data.price || data.surface_minimum) {
          htmlPopin += '<div class="border-bottom border-fifthly p-2">';
          if (data.delivery_date) {
            htmlPopin += `<p class="mb-1">Date de livraison : <span class="font-weight-bold">${data.delivery_date}</span></p>`;
          }
          if (data.nombre_logements) {
            htmlPopin += `<p class="mb-1">Nombre de logements en vente : <span class="font-weight-bold">${data.nombre_logements}</span></p>`;
          }
          if (data.price || data.surface_minimum) {
            htmlPopin += `<p class="m-0">A partir de :</p>`;
            htmlPopin += '<ul class="m-0">';
            if (data.price) {
              htmlPopin += `<li class="font-weight-bold">${goodDisplayForNumbers(data.price)} €</li>`;
            }
            if (data.surface_minimum) {
              htmlPopin += `<li class="font-weight-bold">${goodDisplayForNumbers(data.surface_minimum)}m²</li>`;
            }
            htmlPopin += '</ul>';
          }
          htmlPopin += '</div>';
        }

        if (data.description) {
          htmlPopin += '<div class="border-bottom border-fifthly p-2">';
          htmlPopin += `<div class="m-0 description-container description-small"><small>${data.description}</small></div>`;
          htmlPopin += `<div class="mt-auto w-100 text-center text-muted read-more" style="cursor: pointer;">
                          <img src="./img/ancre-bas.svg" alt="Ancre bas">
                          <span class="px-5"><small>Lire la suite</small></span>
                          <img src="./img/ancre-bas.svg" alt="Ancre bas">
                        </div>`;
          htmlPopin += '</div>';
        }

        if (data.image_url) {
          htmlPopin += '<div class="p-2">';
          htmlPopin += `<p class="my-1"><img style="width: 100%;" src="${data.image_url}"/></p>`;
          htmlPopin += '</div>';
        }
        break;
      case 'urban-project':
        htmlPopin += '<div class="border-bottom border-fifthly p-2">';
        htmlPopin += data.start_date ? `<p class="m-0">Début du projet : ${dateTransform(data.start_date)}</p>` : '';
        htmlPopin += data.delivery_date ? `<p class="m-0">Fin du projet prévue : ${dateTransform(data.delivery_date)}</p>` : '';
        htmlPopin += '</div>';

        if (data.description) {
          htmlPopin += '<div class="border-bottom border-fifthly p-2">';
          htmlPopin += `<div class="m-0 description-container description-small"><small>${data.description}</small></div>`;
          htmlPopin += `<div class="mt-auto w-100 text-center text-muted read-more" style="cursor: pointer;">
                          <img src="./img/ancre-bas.svg" alt="Ancre bas">
                          <span class="px-5"><small>Lire la suite</small></span>
                          <img src="./img/ancre-bas.svg" alt="Ancre bas">
                        </div>`;
          htmlPopin += '</div>';
        }

        if (data.pictures.length > 0) {
          htmlPopin += '<div class="p-2">';
          data.pictures.forEach(picture => {
            htmlPopin += picture ? `<p class="my-1"><img style="width: 100%;" src="${picture}"/></p>` : '';
          });
          htmlPopin += '</div>';
        }
        break;
      case 'fragmentation-info':
        if (data && data['data_0']) {
          htmlPopin += '<div class="border-bottom border-fifthly p-2 d-flex flex-column-reverse">';
          Object.keys(data).forEach(key => {
            if (key.indexOf('data') >= 0) {
              if (data[key].total) {
                htmlPopin += `<p class="m-0">Classement : <b>${data[key].value}</b> / ${data[key].total}</p>`;
              } else if (data[key].unit) {
                if (data[key].value) {
                  htmlPopin += `<p class="m-0">${data[key].title} : <b>${goodDisplayForNumbers(data[key].value, 0)} ${data[key].unit}</b></p>`;
                }
              } else {
                htmlPopin += `<p class="m-0">${data[key].title} : <b>${goodDisplayForNumbers(data[key].value)}</b></p>`;
              }
            }
          });
          htmlPopin += '</div>';
        }

        if (data.description) {
          htmlPopin += '<div class="border-bottom border-fifthly p-2">';
          htmlPopin += `<div class="m-0 description-container description-small"><small>${data.description}</small></div>`;
          htmlPopin += `<div class="mt-auto w-100 text-center text-muted read-more" style="cursor: pointer;">
                          <img src="./img/ancre-bas.svg" alt="Ancre bas">
                          <span class="px-5"><small>Lire la suite</small></span>
                          <img src="./img/ancre-bas.svg" alt="Ancre bas">
                        </div>`;
          htmlPopin += '</div>';
        }

        if (data.s3_photo_uri) {
          htmlPopin += '<div class="p-2">';
          htmlPopin += `<p class="my-1"><img style="width: 100%;" src="${data.s3_photo_uri}"/></p>`;
          htmlPopin += '</div>';
        }
        break;
      default:
        console.error(`Template element not found for ${type}`);
        break;
    }

    htmlPopin += '</div>';
    htmlPopin += '</div>';
    htmlPopin += '<div class="position-sticky bg-white p-2" style="bottom: 0px;"><a href="#contact_section" class="btn btn-secondary text-white w-100 contact-button">En savoir plus</a></div>';

    $(htmlPopin).appendTo(this.DOM_ELEMENT_CONTAINER);

    this.open();
  }

  unloadData () {
    this.type = '';
    this.title = '';
    window.investirouMap.releaseClickedMarker();
    this.DOM_ELEMENT_CONTAINER.empty();
  }

  bindButtons () {
    let trackingType = '';
    switch (this.type) {
      case 'real-estate-marker':
        trackingType = 'popup_real_estate_';
        break;
      case 'urban-project':
        trackingType = 'popup_urban_project_';
        break;
      case 'fragmentation-info':
        trackingType = 'popup_commune_';
        break;
    }

    $('.contact-button', this.DOM_ELEMENT).off().on('click', (event) => {
      event.preventDefault();
      event.stopPropagation();

      ahoyTracking.trackClick(`${trackingType}contact_button`);

      $('html, body').animate({ scrollTop: $('#contact_section').offset().top - $('.header-mobile').get(0).getBoundingClientRect().height }, 1000);
    });

    $('#close-map-info', this.DOM_ELEMENT).off().on('click', () => {
      ahoyTracking.trackClick(`${trackingType}close_button`);
      this.close();
    });

    $('.read-more', this.DOM_ELEMENT).off().on('click', ({ currentTarget }) => {
      if ($('small', currentTarget).html() === 'Masquer') {
        ahoyTracking.trackClick(`${trackingType}read_less_button`);
        $('img', currentTarget).css('transform', 'rotate(0deg)');
        $('small', currentTarget).html('Lire la suite');
      } else {
        ahoyTracking.trackClick(`${trackingType}read_more_button`);
        $('img', currentTarget).css('transform', 'rotate(180deg)');
        $('small', currentTarget).html('Masquer');
      }

      $('.description-container', this.DOM_ELEMENT).toggleClass('description-small');
    });
  }

  unbindButtons () {
    $('.contact-button', this.DOM_ELEMENT).off();
    $('#close-map-info', this.DOM_ELEMENT).off();
    $('.read-more', this.DOM_ELEMENT).off();
  }

  setTitle (type, data) {
    this.title = '';

    switch (type) {
      case 'real-estate-marker':
        this.title = data.title ? data.title : '';
        break;
      case 'urban-project':
        this.title = data.title ? data.title : '';
        break;
      case 'fragmentation-info':
        this.title = data.title ? data.title : '';
        break;
    }
  }

}
