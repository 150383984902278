import ahoyTracking from '../AhoyTracking.js';

L.Control.zoomHome = L.Control.extend({

  options: {
    position: 'topleft',
    zoomInText: '+',
    zoomInTitle: 'Zoom in',
    zoomOutText: '−',
    zoomOutTitle: 'Zoom out',
    zoomHomeText: '⌖',
    zoomHomeTitle: 'Zoom home',
    mapBackgroundText: '<>',
    mapBackgroundTitle: 'Zoom home',
  },

  initialize: function (options) {
    L.Util.setOptions(this, options);
    if (options.position) {
      this.setPosition(options.position);
    }
  },

  onAdd: function (map) {
    let controlName = 'leaflet-control-zoom',
        container = L.DomUtil.create('div', controlName + ' leaflet-bar'),
        options = this.options;

    this._zoomHomeButton = this._createButton(options.zoomHomeText, options.zoomHomeTitle, controlName + '-home', container, this._zoomHome);
    this._zoomInButton = this._createButton(options.zoomInText, options.zoomInTitle, controlName + '-in', container, this._zoomIn);
    this._zoomOutButton = this._createButton(options.zoomOutText, options.zoomOutTitle, controlName + '-out', container, this._zoomOut);
    this._mapBackgroundButton = this._createButton('<div class="w-100"><img src="./img/carte-on.svg" class="w-75" /></div>', options.mapBackgroundTitle, controlName + '-out', container, this._mapBackground);

    this._updateDisabled();
    map.on('zoomend zoomlevelschange', this._updateDisabled, this);

    return container;
  },

  onRemove: function (map) {
    map.off('zoomend zoomlevelschange', this._updateDisabled, this);
  },

  _zoomIn: function (e) {
    this._map.zoomIn(e.shiftKey ? 3 : 1);
    ahoyTracking.trackClick('map_zoom_in', { zoom_level: this._map.getZoom() });
  },

  _zoomOut: function (e) {
    this._map.zoomOut(e.shiftKey ? 3 : 1);
    ahoyTracking.trackClick('map_zoom_out', { zoom_level: this._map.getZoom() });
  },

  _zoomHome: function (e) {
    this._map.setView(this.options.centerMapLatLon, this.options.zoomLevel);
    ahoyTracking.trackClick('map_center_button');
  },

  _mapBackground: function (e) {
    document.getElementById('map-background-selector-container').classList.toggle("d-none");

    if (document.getElementById('map-background-selector-container').classList.contains("d-none")) {
      ahoyTracking.trackClick('map_background_list', { effect: 'close' });
    } else {
      ahoyTracking.trackClick('map_background_list', { effect: 'open' });
    }
  },

  _createButton: function (html, title, className, container, fn) {
    var link = L.DomUtil.create('a', className, container);
    link.innerHTML = html;
    link.href = '#';
    link.title = title;

    L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
      .on(link, 'click', L.DomEvent.stop)
      .on(link, 'click', fn, this)
      .on(link, 'click', this._refocusOnMap, this);

    return link;
  },

  _updateDisabled: function () {
    var map = this._map,
        className = 'leaflet-disabled';

    L.DomUtil.removeClass(this._zoomInButton, className);
    L.DomUtil.removeClass(this._zoomOutButton, className);

    if (map._zoom === map.getMinZoom()) {
        L.DomUtil.addClass(this._zoomOutButton, className);
    }
    if (map._zoom === map.getMaxZoom()) {
        L.DomUtil.addClass(this._zoomInButton, className);
    }
  }
});
