import * as $ from 'jquery';
import { goodDisplayForNumbers } from './utils.js';

export default class LegendBlock {

  constructor () {
    this.displayed = false;
  }

  display (data, title = null) {
    if (typeof data.metadata.title !== 'undefined') {
      $('#legend-title', '#legend-container').html(data.metadata.title);
    } else if (data.type) {
      switch (data.type) {
        case 'price_evolution':
          $('#legend-title', '#legend-container').html('Evolution des prix immobilier entre 2014 et 2021');
          break;
        case 'rental_pressures':
          $('#legend-title', '#legend-container').html('Tension locative en temps réel');
          break;
        case 'return_rates':
          $('#legend-title', '#legend-container').html('Rentabilité brute en temps réel');
          break;
      }
    } else {
      $('#legend-title', '#legend-container').html('Légende');
    }

    switch (data.metadata.legend.type) {
      case 'evolution_bicolor':
        this.createEvolutionBicolor(data);
        break;
      case 'camaieu':
        this.createCamieu(data);
        break;
    }

    $('#legend-container').removeClass('d-none');
    $('#legend-container-mobile-opener').removeClass('d-none');

    L.DomEvent.disableScrollPropagation(document.getElementById('legend-container'));
    L.DomEvent.disableClickPropagation(document.getElementById('legend-container'));
  }

  hide () {
    $('#legend-title', '#legend-container').html('');
    $('#legend', '#legend-container').empty();
    $('#legend-container').addClass('d-none');
    $('#legend-container-mobile-opener').addClass('d-none');

    this.displayed = false;
  }

  createCamieu (data) {
    let extremities = this.defineCamaieuExtremities(data.features, data.type);

    let legendData = data.metadata.legend;
    let gradient = 'linear-gradient(90deg, ';
    if (legendData.colors.length > 0) {
      for (let i = 0; i < legendData.colors.length; i++) {
        let percent = (100 / legendData.colors.length) * i;
        gradient += `${legendData.colors[i]} ${percent}%`;
        if (i < legendData.colors.length - 1) {
          gradient += ', ';
        }
      }
    }
    gradient += ')';

    $(`<div></div>`, {
      html: `
        <div class="legend-gradient" style="background: ${gradient};"></div>
        <div class="container p-0 mt-2">
          <div class="row">
            <div class="col text-left" id="legend-low">${extremities.low ? extremities.low : legendData.low_extremity.title}</div>
            <div class="col text-right" id="legend-high">${extremities.high ? extremities.high : legendData.high_extremity.title}</div>
          </div>
        </div>`
    }).appendTo('#legend', '#legends-container');

    this.displayed = true;
  }

  defineCamaieuExtremities (features, type) {
    let low = null;
    let high = null;

    features.forEach(feature => {
      if (low === null || low < feature.properties.metric) {
        low = feature.properties.metric;
      }

      if (high === null || high > feature.properties.metric) {
        high = feature.properties.metric;
      }
    });

    if (type === 'rental_pressures') {
      return { low: `${low} jours`, high: `${high} jours` };
    } else {
      return { low: `${high}%`, high: `${low}%` };
    }
  }

  createEvolutionBicolor (data) {
    let bounds = {};
    bounds.positive = [];
    bounds.negative = [];

    let minValuePassed = false;

    Object.keys(data).forEach(key => {
      if (key.indexOf('bound') >= 0 && key.indexOf('color') < 0) {
        let keyIndex = key.substr(-1)*1;

        if (key.indexOf('positive') >= 0) {
          if (data[key]) {
            bounds.positive[keyIndex] = { value: data[key], color: data[`color_positive_bound_${keyIndex}`] };

            if (typeof data[`positive_bound_${keyIndex+1}`] !== 'undefined') {
              bounds.positive[keyIndex]['text'] =  `de +${goodDisplayForNumbers(data[`positive_bound_${keyIndex+1}`], 1)}% à +${goodDisplayForNumbers(data[key], 1)}%`;
            } else {
              bounds.positive[keyIndex]['text'] = `de 0% à +${goodDisplayForNumbers(data[key], 1)}%`;
            }
          }
        } else if (key.indexOf('negative') >= 0) {
          if (data[key] && !minValuePassed) {
            bounds.negative[keyIndex] = { value: data[key], color: data[`color_negative_bound_${keyIndex}`] };

            if (typeof data[`negative_bound_${keyIndex-1}`] !== 'undefined') {
              bounds.negative[keyIndex]['text'] =  `de -${goodDisplayForNumbers(data[`negative_bound_${keyIndex-1}`], 1, null, '-')}% à -${goodDisplayForNumbers(data[key], 1, null, '-')}%`;
            } else {
              bounds.negative[keyIndex]['text'] = `de 0% à -${goodDisplayForNumbers(data[key], 1, null, '-')}%`;
            }

            if (data[key] === data.min_value) {
              minValuePassed = true;
            }
          }
        }
      }
    });

    let positiveLine = '';
    bounds.positive.forEach(positive => {
      positiveLine += `
        <div class="evolution-bicolor-legend-line">
          <div class="evolution-bicolor-legend-color" style="background-color: ${positive.color}"></div>
          <div class="evolution-bicolor-legend-text">${positive.text}</div>
        </div>`;
    });

    let negativeLine = '';
    bounds.negative.forEach(negative => {
      negativeLine += `
        <div class="evolution-bicolor-legend-line">
          <div class="evolution-bicolor-legend-color" style="background-color: ${negative.color}"></div>
          <div class="evolution-bicolor-legend-text">${negative.text}</div>
        </div>`;
    });

    $(`<div></div>`, {
      html: `
        ${positiveLine}
        ${negativeLine}`,
      class: 'evolution-bicolor-legend'
    }).appendTo('#legend', '#legends-container');

    this.displayed = true;
  }

}
