import * as $ from 'jquery';
import ahoyTracking from './AhoyTracking.js';

import investirouBackend from './InvestirouBackend.js';

$('input', '#registration-form').off().on('change', ({ currentTarget }) => {
    let field = $(currentTarget).attr('name');
    ahoyTracking.trackFormChanged(field);
  });

$('#registration-form').on('submit', event => {
  event.preventDefault();
  event.stopPropagation();

  $('input', '#registration-form').removeClass('is-invalid');
  $('#registration-form').parent().find('.invalid-feedback').html('');
  $('.alert', '#registration-form').addClass('d-none');

  let form = event.currentTarget;

  let fieldsFormError = {
    email: false,
    first_name: false,
    last_name: false,
    phone: false,
    search_data_consent: false
  };

  let userData = {
    "account": {},
    "user_consents_attributes": {},
    "email": '',
    "website": ''
  };

  let errorTracking = {};

  $('input', form).each((id, elmt) => {
    switch ($(elmt).attr('name')) {
      case 'email':
        if ($(elmt).val().indexOf('@') >= 0) {
          userData[$(elmt).attr('name')] = $(elmt).val();

          fieldsFormError[$(elmt).attr('name')] = false;
        } else {
          userData[$(elmt).attr('name')] = '';
          $('#email', '#registration-form').addClass('is-invalid');
          $('#email', '#registration-form').parent().find('.invalid-feedback').html("Format d'email invalide, exemple : john@doe.fr");

          errorTracking[$(elmt).attr('name')] = 'format invalide';

          fieldsFormError[$(elmt).attr('name')] = true;
        }
        break;
      case 'first_name':
      case 'last_name':
        if (/^[\w-àáâãäåçèéêëìíîïðòóôõöùúûüýÿ]+$/.test($(elmt).val())) {
          userData.account[$(elmt).attr('name')] = $(elmt).val();

          fieldsFormError[$(elmt).attr('name')] = false;
        } else {
          userData.account[$(elmt).attr('name')] = '';
          $(`#${$(elmt).attr('name')}`, '#registration-form').addClass('is-invalid');
          $(`#${$(elmt).attr('name')}`, '#registration-form').parent().find('.invalid-feedback').html("Doit être rempli, avec des lettres uniquement");

          errorTracking[$(elmt).attr('name')] = 'format invalide';

          fieldsFormError[$(elmt).attr('name')] = true;
        }
        break;
      case 'phone':
        let phoneNumber = $(elmt).val() || '00';
        userData.account[$(elmt).attr('name')] = phoneNumber;
        fieldsFormError[$(elmt).attr('name')] = false;

        // if (/^[+](\d{3})\)?(\d{3})(\d{5,6})$|^(\d{10,10})$/.test($(elmt).val())) {
        //   userData.account[$(elmt).attr('name')] = $(elmt).val();

        //   fieldsFormError[$(elmt).attr('name')] = false;
        // } else {
        //   userData.account[$(elmt).attr('name')] = '';
        //   $(`#${$(elmt).attr('name')}`, '#registration-form').addClass('is-invalid');
        //   $(`#${$(elmt).attr('name')}`, '#registration-form').parent().find('.invalid-feedback').html("Doit être rempli, exemple : 0600000000");

        //   errorTracking[$(elmt).attr('name')] = 'format invalide';

        //   fieldsFormError[$(elmt).attr('name')] = true;
        // }
        break;
      case 'search_data_consent':
        if ($(elmt).is(':checked')) {
          userData.user_consents_attributes = { "0": { "search_data_consent": $(elmt).is(':checked') ? 1 : 0 }};

          fieldsFormError[$(elmt).attr('name')] = false;
        } else {
          userData.user_consents_attributes = { "0": { "search_data_consent": 0 }};
          $(`#user_consents`, '#registration-form').addClass('is-invalid');
          $(`#user_consents`, '#registration-form').parent().find('.invalid-feedback').html("Veuillez cocher la case ci-dessus");

          errorTracking[$(elmt).attr('name')] = 'non cochée';

          fieldsFormError[$(elmt).attr('name')] = true;
        }
        break;
      case 'website':
        userData[$(elmt).attr('name')] = $(elmt).val();
        break;
    }
  });

  let errorForm = false;
  for (let field in fieldsFormError) {
    if (fieldsFormError[field]) {
      errorForm = true;
      break;
    }
  }

  if (!errorForm) {
    investirouBackend.registration(userData).then(response => {
      $('.alert', '#registration-form').removeClass('d-none');
      ahoyTracking.trackFormSubmit();
      fbq('track', 'CompleteRegistration');

    }).catch(error => {
      switch (error.status) {
        case 422:
          error.responseJSON.errors.forEach(error => {
            switch (error) {
              case 'Vous avez déjà un compte !':
                $('#email', '#registration-form').addClass('is-invalid');
                $('#email', '#registration-form').parent().find('.invalid-feedback').html(error);

                errorTracking['account'] = 'compte déjà existant';
                break;
              default:
                console.error(`Error not yet handled : ${error}`);
                errorTracking['email'] = `erreur non comprise (${error})`;
                break;
            }
          });
          break;
        default:
          console.error(error);
          break;
      }
      ahoyTracking.trackFormSubmit({ error : true, errors: errorTracking });
    });
  } else {
    ahoyTracking.trackFormSubmit({ error : true, errors: errorTracking });
  }

});


